.public-header {
  background: #000;
  height: 73px;
  padding: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 7px 10px 2px rgb(0 0 0 / 30%);
}

.public-content {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background: linear-gradient(-45deg, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.public-footer {
  text-align: center;
  background-color: #fff;
  box-shadow: 0 -7px 10px 2px rgb(0 0 0 / 30%);
}

.login-button {
  margin: 20px;
}
