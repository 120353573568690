@import "antd/lib/style/themes/default.less";
@import "antd/dist/antd.less"; // Import Ant Design styles by less entry
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200..900&family=Source+Sans+3&family=Asap&family=Archivo&family=Lato&family=Alegreya+Sans&family=Noto+Sans&family=Roboto&family=Rubik&display=swap");

// Set up with https://dev.to/joefstack/how-to-setup-less-with-react-without-ejecting-1nej

body {
  font-family: "Source Sans Pro", "Source Sans 3", "Lato", "Alegreya Sans", "Rubik", "Noto Sans", "Roboto", "Segoe UI",
    Tahoma, Geneva, Verdana, sans-serif;
}

* {-webkit-font-smoothing: antialiased;}

@border-radius-base: 4px; // major border radius
@primary-color: #48a9fe; // primary color for all components
@border-color-base: #999999;
@default-shadow: 0 7px 10px 2px rgb(0 0 0 / 20%);

canvas {
  border-radius: @border-radius-base;
  width: 100% !important;
}

.frosted-card {
  border: none;
  background: rgba(245, 255, 255, 0.6);
  backdrop-filter: blur(3px);
  margin: auto;
  margin-bottom: 24px;
  min-width: 350px;
  box-shadow: @default-shadow;
}

.slightly-frosted-card {
  border: none;
  background: rgba(245, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  margin: auto;
  // margin-bottom: 24px;
  box-shadow: @default-shadow;
}

.ant-btn {
  box-shadow: 0 3px 5px 1px rgb(0 0 0 / 15%);
}

.ant-btn-primary {
  background: linear-gradient(-0deg, #48a9fe, #02c0db);
}

.ant-card {
  box-shadow: @default-shadow;
}

.ant-card-body {
  padding: 20px;
}

.ant-table {
  box-shadow: @default-shadow;
  border-bottom: none;
}

.ant-table table {
  border-radius: @border-radius-base !important;
  border-bottom-right-radius: @border-radius-base !important;
  border-bottom-left-radius: @border-radius-base !important;
}

.ant-table-thead > tr > th {
  position: relative;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 700;
  background: linear-gradient(-0deg, #48a9fe, #02c0db);
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: rgba(245, 255, 255, 0.4);
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 8px;
}

.ant-table-container {
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
}

.ant-table-footer {
  padding: 8px 8px;
  color: rgba(0, 0, 0, 0.85);
  background: linear-gradient(-0deg, #48a9fe, #00b7f1);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  // border: 1px solid #ccc;
  background: grey;
  color: white;
  border: none;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 4px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  // border-bottom: 1px solid #ccc;
  border-bottom: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0px 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: rgba(245, 255, 255, 0.9);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  z-index: 100;
  border-bottom-color: none;
}

.ant-pagination-item,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-options-size-changer {
  box-shadow: 0 3px 5px 1px rgb(0 0 0 / 15%);
}

.ant-collapse {
  box-shadow: @default-shadow;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgba(255, 255, 255, 0.95);
  font-weight: 700;
  background: linear-gradient(-0deg, #48a9fe, #02c0db);
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}

.ant-collapse > .ant-collapse-item-active:last-child,
.ant-collapse > .ant-collapse-item-active:last-child > .ant-collapse-header {
  border-radius: 0 0 0 0;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}

.ant-collapse > .ant-collapse-item:first-child,
.ant-collapse > .ant-collapse-item:first-child > .ant-collapse-header {
  border-radius: 4px 4px 0 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-top: 16px;
  background-color: white
  // box-shadow: rgb(100 100 100 / 20%) 0px -3px 5px 0px inset, rgb(100 100 100 / 20%) 0px 3px 5px 2px inset;
}

.ant-collapse-borderless > .ant-collapse-item:last-child > .ant-collapse-content > .ant-collapse-content-box {
  border-radius: 0 0 4px 4px;
}

.custom-collapse .custom-panel {
  border: 0px;
}

.ant-select-multiple .ant-select-selection-item {
  border: 1px solid @border-color-base;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-label {
  padding: 0 0 4px !important;
}

.ant-table {
  line-height: 1.2;
}

.ant-table-selection-col {
  width: 52px;
}

.ant-typography-ellipsis {
  margin-bottom: 0px !important;
}

.ant-input {
  --top-shadow: inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;
  --bottom-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.7);
  &:before {
    box-shadow: var(--bottom-shadow);
  }
  &:after {
    box-shadow: var(--top-shadow);
  }
  // border: none;
  // box-shadow: 0 7px 10px 2px rgb(0 0 0 / 15%);
}

.ant-modal-footer {
  padding: 16px;
}

.l7-ctrl-logo {
  display: none !important;
}

.top-squared {
  border-top-left-radius: 0px;
}

.study-description-col {
  background-color: rgba(235, 255, 255, 0.9);;
}

.ant-table-thead > tr > .description-header {
  // position: relative;
  color: #323232;
  // font-weight: 700;
  // text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
  text-shadow: 1px 0 0 #eee, 0 1px 0 #eee;
  background: linear-gradient(0deg, #a3b8c2, #c5deeb);
}

.description-header {
  padding: 8px !important;
}

.description-table .ant-table-footer {
  background: linear-gradient(0deg, #a3b8c2, #c5deeb);
}

.mg-btm-0 {
  margin-bottom: 0px !important;
}

.mg-btm-8 {
  margin-bottom: 8px;
}

.mg-btm-16 {
  margin-bottom: 16px;
}

.pd-0 {
  padding: 0px;
}

th.header-0 {
  padding: 0px !important;
}

th.ant-table-cell.study-description-col.header-0::before {
  background-color: transparent !important;
}

// .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before.header-0 {
//   background-color: red;
// }

.dot-loader {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 1400ms infinite;      
  animation: ellipsis steps(4,end) 1400ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.load:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  /* animation: ellipsis-dot steps(40, start) 9000ms infinite; */
  animation: ellipsis .7s linear 0s infinite alternate-reverse;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0em;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
